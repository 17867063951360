<template>
  <div class="catalog-setting">
    <el-dialog class="catalog-setting-dialog" title="内容设置" :visible.sync="visible" :catalogSettingDialogVisible="catalogSettingDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="80%">
      <el-form ref="catalogSettingForm">
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-table class="tableDialog" :data="tableData" style="width: calc(100% - 1.04167vw);margin: 1.04167vw 0.52083vw;" ref="dialog-table" row-key="id" border default-expand-all :tree-props="{ children: 'contentChildren', hasChildren: 'hasChildren', }">
                <el-table-column type="index" label="序号" width="80" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.catalogueName === "" ? "" : (scope.$index+1) }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="catalogueName" label="一级" min-width="200">
                  <template slot-scope="scope">
                    <span>{{ (scope.row.parentItemId !== null && scope.row.parentItemId !== undefined && scope.row.parentItemId !== "") ? "-" : (scope.row.catalogueName ? scope.row.catalogueName : "") }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="catalogueName" label="二级" min-width="200">
                  <template slot-scope="scope">
                    <span>{{ (scope.row.parentItemId !== null && scope.row.parentItemId !== undefined && scope.row.parentItemId !== "") ? scope.row.catalogueName : (scope.row.parentItemId === null ? "/" : "") }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="catalogueType" label="类型" width="150">
                  <template slot-scope="scope">
                    <span>{{ (scope.row.parentItemId !== null && scope.row.parentItemId !== undefined && scope.row.parentItemId !== "") ? scope.row.catalogueTypeName : (scope.row.parentItemId === null ? (scope.row.contentChildren.length > 0 ? "/" : scope.row.catalogueTypeName) : "") }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="isRequired" label="必填">
                  <template slot-scope="scope">
                    <span>{{ (scope.row.parentItemId !== null && scope.row.parentItemId !== undefined && scope.row.parentItemId !== "") ? (scope.row.isRequired === "1" ? "是" : "否") : (scope.row.parentItemId === null ? (scope.row.contentChildren.length > 0 ? "/" : (scope.row.isRequired === "1" ? "是" : "否")) : "") }}</span>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="250">
                  <template slot-scope="scope">
                    <span v-if="(scope.row.parentItemId !== null && (scope.row.sortIndex != 0 && scope.row.sortIndex != undefined)) || (scope.row.parentItemId === null && scope.$index != 0)" class="table-more-edit" @click="handleItem('moveUp', scope.row)">上移</span>
                    <span v-if="(scope.row.parentItemId !== null && (scope.row.sortIndex != scope.row.childrenLength - 1 && scope.row.sortIndex != undefined)) || (scope.row.parentItemId === null && scope.row.sortIndex != tableData.length - 2)" class="table-more-edit" @click="handleItem('moveDown', scope.row)">下移</span>
                    <span v-if="scope.row.catalogueName !== ''" class="table-more-edit" @click="handleItem('edit', scope.row)">编辑</span>
                    <span v-if="scope.row.parentItemId === null || scope.row.catalogueName === ''" class="table-more-edit" @click="handleItem('add', scope.row)">添加</span>
                    <span v-if="scope.row.catalogueName !== ''" class="table-del" @click="handleDel(scope.row)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="catalogButtonloading" @click="handleClose">取 消</el-button>
        <el-button v-if="catalogSettingDialogHandleType == 'add'" :loading="catalogButtonloading" type="primary" @click="handleSubmit">{{ catalogSettingDialogType == "introduce" ? "完 成" : "下一步" }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="catalogItemTitle" :visible.sync="catalogItemDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleCatalogItemDialogClose" @close="handleCatalogItemDialogClose" width="80%">
      <el-form :model="catalogItemform" ref="catalogItemform" label-width="6.25vw" :rules="catalogItemRules">
        <el-form-item label="名称：" class="form-article-item" prop="catalogueName">
          <el-input class="form-input" v-model="catalogItemform.catalogueName" placeholder="请输入名称" :maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="是否必填：" class="form-article-item">
          <el-switch v-model="catalogItemform.isRequired"></el-switch>
          <!-- <el-radio-group v-model="catalogItemform.isRequired">
            <el-radio :label="'1'">是</el-radio>
            <el-radio :label="'0'">否</el-radio>
          </el-radio-group> -->
        </el-form-item>
        <el-form-item label="类型：" class="form-article-item">
          <el-radio-group v-model="catalogItemform.catalogueType">
            <el-radio :label="'0'">图文</el-radio>
            <el-radio :label="'1'">视频</el-radio>
            <el-radio :label="'2'" v-if="catalogSettingDialogType == 'content'">活动</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleCatalogItemDialogClose">取 消</el-button>
        <el-button :loading="buttonloading" type="primary" @click="handleCatalogItemDialogSubmit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { noSymbol } from "@/utils/validate.js";
export default {
  name: "catalogSetting",
  props: {
    catalogSettingDialogVisible: {
      type: Boolean,
      default: false,
    },
    catalogSettingDialogHandleType: {
      type: String,
      default: "",
    },
    catalogSettingDialogType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: {},
    },
    courseId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    catalogSettingDialogVisible() {
      this.visible = this.catalogSettingDialogVisible;
      if (this.catalogSettingDialogVisible) {
      }
    },
    catalogSettingDialogType(val) {
      if (val == "content") {
        this.title = "主题or活动";
        this.listUrl = "/api/educoursethemecataloguecontent/list";
        this.addUrl = "/api/educoursethemecataloguecontent";
        this.updateUrl = "/api/educoursethemecataloguecontent";
        this.moveUrl = "/api/educoursethemecataloguecontent/move";
        this.delUrl = "/api/educoursethemecataloguecontent/del";
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
          customClass: "loadingStyle"
        });
        this.getContentCataLogList();
      } else if (val == "introduce") {
        this.title = "课程介绍";
        this.listUrl = "/api/educourseintro/list";
        this.addUrl = "/api/educourseintro";
        this.updateUrl = "/api/educourseintro";
        this.moveUrl = "/api/educourseintro/move";
        this.delUrl = "/api/educourseintro/del";
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
          customClass: "loadingStyle"
        });
        this.getCataLogList();
      } else {
        this.title = "";
        this.listUrl = "";
        this.addUrl = "";
        this.updateUrl = "";
        this.moveUrl = "";
        this.delUrl = "";
      }
    },
  },
  data() {
    let checkName = (rule, value, callback) => {
      if (value === "") {
        return callback();
      }
      if (value !== "" && !noSymbol(value)) {
        callback(new Error('只支持汉字、数字、字母组合'));
      } else {
        callback();
      }
    };
    return {
      visible: false, //弹框显隐
      buttonloading: false, //添加目录项的弹框按钮loading
      catalogButtonloading: false, // 目录列表弹框按钮loading

      title: "", //表格标题
      listUrl: "", //列表请求接口
      addUrl: "", //添加请求接口
      updateUrl: "", //更新请求接口
      moveUrl: "", //移动请求接口
      delUrl: "", //删除请求接口

      catalogItemTitle: "", //目录项弹窗标题
      tableData: [], //目录表格
      catalogItemDialogVisible: false, //目录项弹窗显隐标识

      catalogItemId: "", //目录内容id
      parentItemId: "", //目录内容上级id
      catalogItemform: {
        catalogueName: "",
        isRequired: true,
        catalogueType: "0",
      }, //目录项form
      addCol: {
        catalogueName: "",
        parentItemId: "",
        catalogueType: "",
        catalogueTypeName: "",
        isRequired: "",
      },
      catalogItemRules: {
        catalogueName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 2, message: "名称长度不能小于2", trigger: "blur" },
          // { validator: checkName, trigger: 'blur' }
        ],
      },

      catalogContentItemId: "", //目录内容id
      parentContentItemId: "", //目录内容上级id
      catalogContentItemform: {
        contentName: "",
        isRequired: true,
        contentType: "0",
      }, //目录项form
      catalogContentItemRules: {
        contentName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 2, message: "名称长度不能小于2", trigger: "blur" },
        ],
      },
      pageLoading: null, // 页面loading
    };
  },
  methods: {
    //获取内容设置list
    getCataLogList() {
      this.$api
        .contentHandle(this.listUrl, { courseId: this.courseId }, "list")
        .then((res) => {
          this.pageLoading.close()
          if (res.data.code === 0) {
            let contentList = []
            res.data.data.map((item, i) => {
              let contentItem = {...item}
              contentItem.parentItemId = contentItem.parentCatalogueId
              // delete contentItem.parentCatalogueId
              contentItem.catalogueTypeName = contentItem.catalogueType === '0' ? '图文' : '视频'
              contentItem.sortIndex = i
              // delete contentItem.eduCourseIntroList
              if(contentItem.eduCourseIntroList.length > 0){
                contentItem.eduCourseIntroList.map((info,j) => {
                  info.parentItemId = info.parentCatalogueId
                  info.sortIndex = j
                  info.childrenLength = contentItem.eduCourseIntroList.length
                  info.catalogueTypeName = info.catalogueType === '0' ? '图文' : '视频'
                })
              }
              contentItem.contentChildren = JSON.parse(JSON.stringify(contentItem.eduCourseIntroList))
              contentList.push(contentItem)
            })
            console.log(contentList)
            this.tableData = contentList;
            let col = { ...this.addCol };
            this.tableData.push(col);
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(() => {
          this.pageLoading.close()
        });
    },
    //获取课程内容下目录的内容设置list
    getContentCataLogList(){
      this.$api
        .contentHandle(this.listUrl, { courseThemeCatalogueId: this.courseId }, "list")
        .then((res) => {
          this.pageLoading.close()
          if (res.data.code === 0) {
            let contentList = []
            res.data.data.map((item, i) => {
              let contentItem = {...item}
              contentItem.catalogueName = item.contentName
              contentItem.parentItemId = contentItem.parentCatalogueContentId
              // delete contentItem.parentCatalogueId
              contentItem.catalogueType = contentItem.contentType
              contentItem.catalogueTypeName = contentItem.contentType === '0' ? '图文' : (contentItem.contentType === '1' ? '视频' : (contentItem.contentType === '2' ? '活动' : ""))
              contentItem.sortIndex = i
              // delete contentItem.eduCourseIntroList
              if(contentItem.eduCourseThemeCatalogueContent){
                contentItem.eduCourseThemeCatalogueContent.map((info,j) => {
                  info.catalogueName = info.contentName
                  info.parentItemId = info.parentCatalogueContentId
                  info.sortIndex = j
                  info.childrenLength = contentItem.eduCourseThemeCatalogueContent.length
                  info.catalogueType = info.contentType
                  info.catalogueTypeName = info.contentType === '0' ? '图文' : (info.contentType === '1' ? '视频' : (info.contentType === '2' ? '活动' : ""))
                })
              }
              contentItem.contentChildren = contentItem.eduCourseThemeCatalogueContent ? JSON.parse(JSON.stringify(contentItem.eduCourseThemeCatalogueContent)) : []
              contentList.push(contentItem)
            })
            console.log(contentList)
            this.tableData = contentList;
            let col = { ...this.addCol };
            this.tableData.push(col);
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(() => {
          this.pageLoading.close()
        });
    },
    //目录设置弹窗关闭
    handleClose() {
      this.catalogButtonloading = false;
      this.$emit("refreshPage", false);
    },
    //目录设置弹窗保存
    handleSubmit() {
      this.$emit("refreshPage", true);
    },
    //表格操作
    handleItem(type, row) {
      switch (type) {
        case "add":
          this.catalogItemDialogVisible = true;
          this.$nextTick(() => {
            this.parentItemId = row.id !== undefined && row.id !== "" ? row.id : ""
            this.catalogItemTitle = "添加";
          });
          break;
        case "moveUp":
          this.moveCatalog("up", row);
          break;
        case "moveDown":
          this.moveCatalog("down", row);
          break;
        case "edit":
          this.catalogItemId = row.id;
          this.catalogItemDialogVisible = true;
          this.$nextTick(() => {
            this.catalogItemTitle = "编辑";
            this.catalogItemform.catalogueName = row.catalogueName;
            this.catalogItemform.isRequired =
              row.isRequired == "1" ? true : false;
            this.catalogItemform.catalogueType = row.catalogueType;
          });
          break;
      }
    },
    //移动事件
    moveCatalog(type,row) {
      let itemIndex = null;
      let itemList = null;
      if (row.parentItemId !== null) {
        itemList = JSON.parse(
          JSON.stringify(
            this.tableData.find((item) => {
              return item.id == row.parentItemId;
            }).contentChildren
          )
        );
        itemIndex = itemList.findIndex((info) => {
          return info.id == row.id;
        });
      } else {
        itemList = JSON.parse(JSON.stringify(this.tableData));
        itemIndex = itemList.findIndex((item) => {
          return item.id == row.id;
        });
      }
      let params = {
        id: row.id,
        sort: row.sort,
      };
      if (type == "up") {
        params.moveId = itemList[itemIndex - 1].id;
        params.moveSort = itemList[itemIndex - 1].sort;
      } else if (type == "down") {
        params.moveId = itemList[itemIndex + 1].id;
        params.moveSort = itemList[itemIndex + 1].sort;
      }
      this.$api.contentHandle(this.moveUrl, params, "move").then((res) => {
        if (res.data.code === 0) {
          this.$message({ type: "success", message: "移动成功!", duration: 2000, onClose: () => {
            if(this.catalogSettingDialogType == 'introduce'){
              this.getCataLogList();
            } else if(this.catalogSettingDialogType == 'content'){
              this.getContentCataLogList();
            }
          }, });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //删除操作
    handleDel(row) {//"确定删除该内容, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
            h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该内容？'),
            h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该内容，删除后，将无法恢复。')
        ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage"
      }).then(() => {
        this.$api.contentHandle(this.delUrl, { id: row.id, delFlag: "1" }, "del").then((res) => {
          if (res.data.code === 0) {
            this.$message({ type: "success", message: "删除成功!", duration: 2000, onClose: () => {
              this.pageLoading = this.$loading({
                lock: true,
                fullscreen: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: "loadingStyle"
              });
              if(this.catalogSettingDialogType == 'introduce'){
                this.getCataLogList();
              } else if(this.catalogSettingDialogType == 'content'){
                this.getContentCataLogList();
              }
            }, });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => {});
    },
    //目标项弹窗关闭
    handleCatalogItemDialogClose() {
      this.catalogItemDialogVisible = false;
      this.$nextTick(() => {
        this.catalogItemform = {
          catalogueName: "",
          isRequired: true,
          catalogueType: "0",
        };
        this.catalogItemTitle = "";
        this.catalogItemId = "";
        this.parentItemId = ""
        this.buttonloading = false;
        this.$refs.catalogItemform.clearValidate()
      });
    },
    //目标项弹窗保存
    handleCatalogItemDialogSubmit() {
      this.$refs.catalogItemform.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = null
          let url = "";
          if(this.catalogSettingDialogType == 'introduce'){
            params = {
              courseId: this.courseId,
              catalogueName: this.catalogItemform.catalogueName,
              isRequired: this.catalogItemform.isRequired ? '1' : '0',
              catalogueType: this.catalogItemform.catalogueType,
              parentCatalogueId: this.parentItemId
            };
            if (this.catalogItemId == "") {
              url = this.addUrl;
            } else {
              params.id = this.catalogItemId;
              url = this.updateUrl;
            }
          } else if(this.catalogSettingDialogType == 'content'){
            params = {
              courseThemeCatalogueId: this.courseId,
              contentName: this.catalogItemform.catalogueName,
              isRequired: this.catalogItemform.isRequired ? '1' : '0',
              contentType: this.catalogItemform.catalogueType,
              parentCatalogueContentId: this.parentItemId
            };
            if (this.catalogItemId == "") {
              url = this.addUrl;
            } else {
              params.id = this.catalogItemId;
              url = this.updateUrl;
            }
          }
          this.$api.contentHandle(url, params, this.catalogItemId == "" ? "add" : "edit").then((res) => {
            if (res.data.code === 0) {
              this.$message({ type: "success", message: "保存成功!", duration: 2000, onClose: () => {
                this.handleCatalogItemDialogClose();
                this.pageLoading = this.$loading({
                  lock: true,
                  fullscreen: true,
                  text: '加载中',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)',
                  customClass: "loadingStyle"
                });
                if(this.catalogSettingDialogType == 'introduce'){
                  this.getCataLogList();
                } else if(this.catalogSettingDialogType == 'content'){
                  this.getContentCataLogList();
                }
              }, });
            } else {
              this.buttonloading = false;
              this.$message.error(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-table__body-wrapper::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }
/deep/.tableDialog {
  .el-table__fixed-right::before {
    background: transparent;
  }
}
</style>
