import store from '../store/index'

//获取权限list
const getPermissionButton = (url,callback) =>{
  getItem(url,store.state.common.menuList,(data) => {
    let row = data
    callback(row)
  })
}

const getItem = (url,list,callback) => {
  list.map(item => {
    if(item.path == url){
      callback(item.meta.buttons)
      return
    }
    if(item.children){
      getItem(url,item.children,callback)
    }
  })
}

//是否具有权限
const isHasPermission = (url, list) =>{
  console.log(list)
  let row = list.find(item => {
    return item == url
  })
  return row ? true : false
}

export {
  getPermissionButton,
  isHasPermission
}